
//jquery cropper/ обрезка картинок
var jcrop_api = null;
var width = 120;
var height = 180;
var img_w_cr = 0;
var img_h_cr = 0;
window.initJcropAll = function(){
    if ($('#cropbox').length > 0){
        img_w_cr = $('#cropbox').width();
        img_h_cr = $('#cropbox').height();
        $('#cropbox').cropper({
            aspectRatio: 3 / 4,
            minCropBoxWidth: 120,
            minCropBoxHeight: 180,
            crop: function(e) {
                $('#x').val(e.x);
                $('#y').val(e.y);
                $('#x2').val(e.scaleX);
                $('#y2').val(e.scaleY);
                $('#w').val(e.width);
                $('#h').val(e.height);
            }
        });

    }
    // Methods
    $('.docs-buttons').on('click', '[data-method]', function () {
        var $image = $('#cropbox');
        var $this = $(this);
        var data = $this.data();
        var $target;
        var result;

        if ($this.prop('disabled') || $this.hasClass('disabled')) {
            return;
        }

        if ($image.data('cropper') && data.method) {
            data = $.extend({}, data); // Clone a new one

            if (typeof data.target !== 'undefined') {
                $target = $(data.target);

                if (typeof data.option === 'undefined') {
                    try {
                        data.option = JSON.parse($target.val());
                    } catch (e) {
                        console.log(e.message);
                    }
                }
            }

            if (data.method === 'rotate') {
                $image.cropper('clear');
            }

            result = $image.cropper(data.method, data.option, data.secondOption);

            if (data.method === 'rotate') {
                $image.cropper('crop');
            }

            switch (data.method) {
                case 'scaleX':
                case 'scaleY':
                    $(this).data('option', -data.option);
                    break;

                case 'getCroppedCanvas':
                    if (result) {

                        // Bootstrap's Modal
                        $('#getCroppedCanvasModal').modal().find('.modal-body').html(result);

                        if (!$download.hasClass('disabled')) {
                            $download.attr('href', result.toDataURL('image/jpeg'));
                        }
                    }

                    break;

                case 'destroy':
                    if (uploadedImageURL) {
                        URL.revokeObjectURL(uploadedImageURL);
                        uploadedImageURL = '';
                        $image.attr('src', originalImageURL);
                    }

                    break;
            }

            if ($.isPlainObject(result) && $target) {
                try {
                    $target.val(JSON.stringify(result));
                } catch (e) {
                    console.log(e.message);
                }
            }

        }
    });

};
setTimeout(function(){initJcropAll();}, 500);
