// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

////NODE_OPTIONS=--openssl-legacy-provider rails assets:precompile
////NODE_OPTIONS=--openssl-legacy-provider rails webpacker:compile

require("@rails/ujs").start();
require("turbolinks").start();
require("jquery");
require("jquery-ui/ui/widgets/autocomplete");
//require('webpack-jquery-ui');
//require('webpack-jquery-ui/autocomplete');
require("popper.js");
require("bootstrap");
require("cropper");
require('blueimp-gallery/js/jquery.blueimp-gallery');
require("bootstrap-image-gallery/bootstrap-image-gallery");
require("service/our_bootstrap");
require("service/js_cropper")
//require("@rails/activestorage").start();
//require("@rails/actioncable")
//require("pwa-rails");

//require("current/jquery.waypoints.min");
//require("waypoints");
////require('waypoints/lib/jquery.waypoints.js');

