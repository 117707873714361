//import 'webpack-jquery-ui/autocomplete' ; // if you need only autocomplete widget.

$(document).on('ready turbolinks:load', function() {
    getLocTime();
    initAutocomplete();
    var abp=$('.appb');

    if (typeof letts !=='undefined') {
    setInterval(function(){
        btCheckLetters();
    }, 60000);}
	
	$(window).scroll(function () { //перемотка
		if ($(this).scrollTop() > 50) {
			$('#back-to-top').fadeIn();
		} else {
			$('#back-to-top').fadeOut();
		}
	});


	// scroll body to 0px on click
	$('#back-to-top').click(function () {
		//$('#back-to-top').tooltip('hide');
		$('body,html').animate({
			scrollTop: 0
		}, 800);
		return false;
	});
    //$('#fin_menu')[0].scrollIntoView(true);

	$('.more_read').hide(); //скрывает hidden text в стене, форуме.
	
	$('.more_read:not(.more_one)').each(function(){
		$(this).before('<a href="#" onclick="toggleShortText(this); return false;" class="btn read_more btn-sm btn-outline-success">'+lng_read_all+'</a>');
		$(this).addClass('more_one');
	});
	

	//mailbox для платинового показ в почте первых строк
	var more_t_arr = new Array();
	jQuery('.more_t').each(function(){
		if ( $(this).html() == ''){
			more_t_arr.push($(this).attr('rel'));
		}
	});
	
	if (more_t_arr.length > 0){
		$.ajax({
			type : 'POST',
			dataType: 'json', 
			data:{id: more_t_arr.join()},
			url : '/letter/begin_text/',	
			success: function(data, status) {				
				$.each(data, function(i, val) {	
					$('#more_t_' + i).html(val);
				});
			}
		}); 
	}

// закрыть возможность нажимать на кнопку поиска много раз
	$('.btnsmy').attr('disabled', false);

//был скрипт по почте, не поняла зачем
//game
//был скрипт по игре, центровать картинку в центре экрана
//был скрипт по игре проверка что все заполнено- не нужно


 //for disability
    abp.on('click', '.check_show', function(){
        if ($(this).is(':checked')){
            $('#'+$(this).attr('rel')).show();
            $('#'+$(this).attr('rel')+'div')[0].scrollIntoView(false);
        } else {
            $('#'+$(this).attr('rel')).hide();
        }
    });

//awards
	abp.on('click', 'a.hide_reward', function(){
		$(this).parent().find('.img_m').addClass('img-thumbnail');
		var medal = $(this).parent().find('.img_m').attr('data-class');
		$('.' + medal).hide();

		$(this).parent().find('.show_reward').show();
		$(this).hide();
		$.post($(this).attr('href'),'script');

		return false;
	});

    abp.on('click', 'a.show_reward', function(){
		$(this).parent().find('.img_m').removeClass('img-thumbnail');
		$(this).parent().find('.hide_reward').show();
		$(this).hide();
		var medal = $(this).parent().find('.img_m').attr('data-class');
		$('.' + medal).show();
		$.post($(this).attr('href'),'script');
		return false;
	});

//mailbox освновной показ
	if ($('#col_show').length > 0){
		if ($('#col_hide').css('display') != 'none'){
			$('#col_hide,#col_show').height($(window).height() - 67);
			$('#col_hide,#col_show').css('overflow-y', 'scroll');
			
			if ($('.contact.sactive a').length > 0){
				var url = $('.contact.sactive a').attr('href');
				$('.contact.sactive').addClass('active');
				newAjaxChange('mail_letter', url, {}, 'json', function(){getLocTime();});
			} else if ($('.inuid').attr('data-url') != ''){
				var url = "/letter/send_message/" + $('.inuid').attr('data-url');
				newAjaxChange('mail_letter', url, {}, 'json', function(){getLocTime();});
			} else if ($('.contact a').length > 0){
				var url = $('.contact a:first').attr('href');
				$('.contact a:first').parent().parent().addClass('active');
				newAjaxChange('mail_letter', url, {}, 'json', function(){getLocTime();});				
			}
		} 
	}

	
	$('.modal').on('hidden.bs.modal', function (e) {
	  $('.embed-responsive').html('');
	});


// в почте показывать кнопку back в письме в мелком разрешении
    abp.on('click', '.backlist', function(){
        $('#col_show').removeClass('d-none d-md-block');
        $('#col_hide').addClass('d-none d-md-block');
        $('.backlist').hide();

        return false;
    });

    //reply rate
    abp.on('click', '.getrate', function(){
		$.post('/mailbox/details_reply_rate/', function(data){
			$('#raply_rate').html(data);
		});
		
		return false;
	});

    //reply rate
    abp.on('click', '.getnewrate', function(){
		$('.getnewrate img').hide();
		$('#loader_rate').show();
		$.post('/mailbox/details_reply_new/', function(data){
			$('.getrate span').html(data);
		});
		
		setTimeout(function(){$('.getnewrate img').show();$('#loader_rate').hide();}, 1000);
		
		return false;
	});

//general в неск местах. можно посмотреть на добавлении в друзья
    abp.on('click', 'a.ajax_post', function(){
        $.post($(this).attr('href'), function(){},'script');
        return false;
    });
	//ajax elem
    abp.on('click', 'a.ajax_elem', function(){
        var obj =  $(this);
		var rel = $(obj).attr('rel');
		if (rel.indexOf('notice') !== -1){
			var div = rel.replace('notice', 'fl_div');
			$('#' + div).html('');
		} else if (rel.indexOf('fl_div') !== -1){
			var div = rel.replace('fl_div', 'notice');
			$('#' + div).html('');
		}
		var el = $(obj).attr('rel').split(':');
		var type = (el.length > 1 && el[1] == 'get') ? 'GET' : 'POST';
		$.ajax({
			type : type,
			dataType: 'json',
			url : $(this).attr('href'),	
			complete: function(data, status) {				
				if (data.responseText.indexOf("window.location.href=''") !== -1 ){ window.location.href='';}
				if (data.responseText.indexOf('NO_LOGIN') === -1 ){					
					if (el.length > 1){
						if (el[1] == 'bottom'){
							$('#' + el[0]).append(data.responseText);
						} else {						
							$('#' + el[0]).html(data.responseText);
						}
					} else {						
						$('#' + el[0]).html(data.responseText);
					}
					
					$('#trlframe').remove();
					if ($(obj).hasClass('s_top')){
						$(window).scrollTop(0);
					}
					if ($(obj).hasClass('s_elem')){
						$(window).scrollTop($('#' + el[0]).offset().top);
					}
				} else {
					try{
						eval( '(' + data.responseText + ')' );	
						
					} catch (ex) {}
				}
				
				$('.ui-page-active').trigger('create');
				$('.ui-page-active ul').each(function(){
					if ($(this).hasClass('ui-listview')) {
						$(this).listview('refresh');
					} else {
						$(this).trigger('create');
					}
				});
			}
		});
        return false;
	});

    setTimeout(function(){initJcropAll();}, 500);
});
//проверка есть ли письма
window.btCheckLetters = function(){
    $.ajax({
        url: '/mailbox/bt_check_letters/',
        dataType: 'json',
        success: function(data){

			if (data.res.all_new > 0){
				var cur = parseInt($('.all_new_div').html());
				var val = data.res.all_new;
				$('.all_new_div').html(val);
				if (!isNaN(cur) && (cur < parseInt(val))){play_bip_mail()}
			} else {
				$('.all_new_div').html('');
			}
        }
    });

}
//вставка нового письма в переписку js
window.animateLetter = function(){
	$('.new_letter .alert-info').css('background-color', '#ffad2e');
	$('.new_letter .alert-info').animate({'opacity': 0.8}, 500, function (){
		$('.new_letter .alert-info').css('background-color', '#d9edf7').css('opacity', 1);
		$('.new_letter').removeClass('new_letter');
	});
	
}


//обрезает скрытый текст
window.toggleShortText = function(el) {
	$(el).parent().find('.more_read').slideDown(3000);
	$(el).hide();
}

window.getLocTime = function(){
	//массова функция локального времени
	$('time').each(function(){
		var d = new Date();
		var time_ = parseInt($(this).attr('dateint'));
 		if (!isNaN(time_)){
			var t = new Date( time_ * 1000 );
			$(this).html(t.format($(this).attr('dateformat')));
			$(this).removeAttr('dateint');
		}
	});
}


window.edit_cont = function(){
	//редактирование общее в почте (удалять, скрывать письма и тд)
	if ($('.m_check_col').css('display') == 'none'){
		$('.m_check_col').show();
		$('.mypadd_cont').addClass('active');
		$('.m_check_hide').show();
		var w = $('#col_show').width();
		var w2 = $('.m_check_hide').width();
		$('.m_check_hide').css('left', parseInt((w-w2)/ 2) + 'px');
	} else {
		$('.m_check_col').hide();
		$('.mypadd_cont').removeClass('active');
		$('.m_check_hide').hide();
	}

	return false;
}

//photo gallery
window.insertPhotoGal = function(el){
	var objects = $.parseJSON($('input[name="'+el+'"]').val());
	var hide = $('input[name="'+el+'"]').attr('data-hide') == 1 ? 1 : 0 ;
	var html = '<div class="clearfix"><div class="card-group">';
	$(objects).each(function(i, el2){
	   if (hide == 1){
			html += '<div class="card" style="min-width:'+el2.wz+'px;max-width:'+el2.wz+'px"><div class="card-body"><img alt="" src="'+el2.sm+'" class="card-img-top">';
		} else {
			html += '<div class="card" style="min-width:'+el2.wz+'px;max-width:'+el2.wz+'px"><div class="card-body"><a href="'+el2.big+'" data-gallery><img alt="" src="'+el2.sm+'" class="card-img-top"></a>';
		}
		if (el2.tx) {html += '<small class="text-muted">'+el2.tx+'</small>'}
		html += '</div></div>'

	});
	html += '</div></div>';
	$('#'+el).html(html);
}

//увеличить текст арию
// Script copyright (C) 2011 www.cryer.co.uk.
// Script is free to use provided this copyright header is included.
window.AutoGrowTextArea = function(textField)
{
    if (textField.clientHeight < textField.scrollHeight)
    {
        textField.style.height = textField.scrollHeight + "px";
        if (textField.clientHeight < textField.scrollHeight)
        {
            textField.style.height =
                (textField.scrollHeight * 2 - textField.clientHeight) + "px";
        }
    }
}
//end
//цели при закрывании
window.undisabled = function(el){
    if ($(el).is(':checked')){
    } else {
        $(el).parent().parent().find('.line input').removeAttr('checked');
        $(el).parent().parent().find('.radio2,.radio1').hide();
        $(el).removeAttr('checked');
    }
}
//выделить новый текущий аватар

window.formAjax = function(div, url, data, type, callback){

	type = type == '' ? 'html' : type;
	$('input').attr('disabled', true);
	$.ajax({
		type : 'POST',
		dataType: type, 
		url : url,	
		data: data,
		complete: function(data, status) {	
			if (data.responseText.indexOf("window.location.href=''") !== -1 ){ window.location.href='';}
			if (data.responseText != ''){
				if(div != ''){
					$('#'+div).html(data.responseText);
				} 
			} else {
				if(div != ''){
					$('#' + div).html('');
				} 
			}
			
			if(callback){
				callback.call();
			}
			$('input').attr('disabled', false);
		}
	}); 	
}

window.newAjaxChange = function(div, url, data, type, callback){
    type = type == '' ? 'html' : type;
	if(div != ''){
        $.ajax({
            type : 'GET',
            dataType: type,
            data:data,
            url : url,
            complete: function(data, status) {
                if (data.responseText.indexOf("window.location.href=''") !== -1 ){ window.location.href='';}
                $('#'+div).html(data.responseText);
                if (callback) {
                    callback.call();
                }
            }
        });
    } else {
        $.post(url,data, function(){
            if (callback) {
                callback.call();
            }
        },'script');
    }

    return false;
}


//city
window.SelectChange = function(obj){
    if ($(obj).val() == 0){
        $(obj).next().html('<span class="error"></span>');
    } else {
        $(obj).next().html('<span class="good"></span>');
    }
}

window.progressBoot = function(){
	//используется в двух местах, нафига не понятно
	$('.progress').each(function(){
	   $(this).attr('value', $(this).attr('data-v'));
	});
}

window.initAutocomplete = function(){
	//при изменении страны в регистрации и редактировании
	$('#myTab a').click(function (e) {
	  e.preventDefault();
	  $('#myTab a').removeClass('active');
	  $(this).tab('show')
	});

	if ($('#autocomplete-input').length > 0){
		var ln = $("#autocomplete-input" ).attr('data-ln');
		var country_id = $( "#new_country" ).val();
		$( "#autocomplete-input" ).autocomplete({
		  source: "/account/autocomplete_city?l="+ ln +"&country_id="+country_id,
		  minLength: 3,
		  open: function( event, ui ) {
			var v = $('#autocomplete-input').outerWidth();
			$('.ui-autocomplete').width(v - 2);
		  },
		  response: function( event, ui ) {
		      if ($(ui.content).length ){
                  $('#show_not_found span').html('"' + $('#autocomplete-input').val()+'"');
                  $("#autocomplete-input").removeClass('is-valid').addClass('is-invalid');
				  $('#show_not_found').show();
			  } else {
				  $('#show_not_found').hide();
              }
		  },
		  select: function( event, ui ) {
			$( "#city_id" ).val(ui.item.id);
            $("#autocomplete-input").removeClass('is-invalid').addClass('is-valid');
         }
		});
	}
}

window.addCount = function(el, pop){
	if ($(el).parent().find('.add_count span').length == 0){
		$(el).parent().find('.add_count').html($(el).parent().find('.add_count').html() + ' <span>+1</span>');
	}

	showPopover(pop, el);
}

window.showPopover = function(pop_id, el){
	$('.popover').hide();
	$(pop_id).show();

	var left = $(el).offset().left;
	var pw = $(pop_id).outerWidth() > 171 ? $(pop_id).outerWidth() : 171;
	left = left - parseInt(pw / 2) + parseInt($(el).outerWidth() / 2);
	var top = $(el).offset().top;
	top = top + $(el).outerHeight();
	$(pop_id).css('left', left + 'px');
	$(pop_id).css('top', top + 'px');
}
//psy test
var curr_q = 0;
var time = 0;
$(document).on('ready turbolinks:load', function(){
	$('.color-line span.you, .color-line span.partner').each(function(){
		if (parseInt($(this).css('left')) > 50){
			$(this).addClass('right');
		}
	});

	curr_q = $('UL#curr_q LI').index($('UL#curr_q LI.current'));
	time = new Date();

	$('.appb').on('click','.response .prev', function(){
		prevQuestion();
		$(this).hide();
		return false;
	});

	$('.appb').on('click', '.response .btn-group .btn', function(){
	    nextQuestion($('UL#curr_q LI.current').attr('id'), parseInt($(this).attr('rel')));
		this.blur();
		return false;
	});

});

window.setCurrentQuestion = function(){
	$('UL#curr_q LI').removeClass('current');
	$('UL#curr_q LI:eq(' + curr_q + ')').addClass('current');
	curr_q == 0 ? $('.response .prev').hide() : $('.response .prev').show();
	var percent = parseInt((curr_q / $('UL#curr_q LI').length) * 100);
	$('.progress-bar').css('width', percent+'%').text(percent + '%');
	if ($('UL#curr_q LI').length == curr_q){
		$('form#testform').submit();
		$('form#testform, .response').hide();
		return;
	}
}

window.prevQuestion = function(){
	curr_q--;
	var answ_arr = $(' form#testform input[name="answers"]').val().split(',');
	$('form#testform input[name="answers"]').val(answ_arr.slice(0, -1).join(','));
	setCurrentQuestion();
}
window.nextQuestion = function(id, score){
	score = isNaN(score) ? 0 : score;
    var curr_time = new Date();
    var ts = curr_time - time;
    if (ts<300) {
    } else {
    curr_q++;
    time = curr_time;
	var old_val = $('form#testform input[name="answers"]').val();
	var new_val = old_val === '' ? '' : old_val + ',';
	new_val = new_val + id + ':' + score + ':' + ts;
	$('form#testform input[name="answers"]').val(new_val);}
	setCurrentQuestion();
}



window.redirect_js = function(url, callback){
	//из контроллеров js редайректим
	window.location.href = url;
}

window.checkMobile = function(){
	//в письмах
	if ($('#col_show').length > 0){
		if ($('#col_hide').css('display') == 'none' && $('#col_show').css('display') != 'none'){
			$('#col_show').addClass('d-none d-md-block');
			$('#col_hide').removeClass('d-none d-md-block');
			$('.backlist').show();
		} else{
			$('.backlist').hide();
		}
	} else {}
}



window.insertAtCursor = function(myField, myValue) {
	// вставка в письма смайликов
  //IE support
  if (document.selection) {
    myField.focus();
    sel = document.selection.createRange();
    sel.text = myValue;
  }
  //MOZILLA/NETSCAPE support
  else if (myField.selectionStart || myField.selectionStart == '0') {
    var startPos = myField.selectionStart;
    var endPos = myField.selectionEnd;
    myField.value = myField.value.substring(0, startPos)
                  + myValue
                  + myField.value.substring(endPos, myField.value.length);
	setCaretToPos(myField, startPos + 2);
  } else {
    myField.value += myValue;
  }
  myField.focus();

  return false;
}

window.setSelectionRange = function(input, selectionStart, selectionEnd) {
	//доп функция для смайлов
  if (input.setSelectionRange) {
    input.focus();
    input.setSelectionRange(selectionStart, selectionEnd);
  }
  else if (input.createTextRange) {
    var range = input.createTextRange();
    range.collapse(true);
    range.moveEnd('character', selectionEnd);
    range.moveStart('character', selectionStart);
    range.select();
  }
}

window.setCaretToPos = function(input, pos) {
	//доп функция для смайликов
  setSelectionRange(input, pos, pos);
}


window.validate_fl_form = function(count){
	//валидация формы  цветов
    var text=$('textarea.count').val();
   if (text == '' || $('#when_deliver').val() == '' ){
       alert($('#not_filled').val());
      return false
    }  else   {
       if (text.length > count ) {
         alert($('#too_big').val());
         return false
        }  else {
           return true
       }
   }
}

window.enable_sound = function(){
	newAjaxChange('', '/functions/enable_sound/', '', '');
	$('.loading').hide();
	sound_off = false;
	$('#sound_on, #top_sound_on').hide();
	$('#sound_off, #top_sound_off').show();
	return false;
}

window.disable_sound = function(){
	newAjaxChange('', '/functions/disable_sound/', '', '');
	$('.loading').hide();
	$('#sound_off, #top_sound_off').hide();
	$('#sound_on, #top_sound_on').show();
	sound_off = true;
	return false;
}

window.play_bip_mail = function() {
	//звук при новом письме
	if (!sound_off){
		if (!$('#audio').length ){
			$('body').append('<audio id="audio"><source src="/audio/xmpp/beep.mp3">  <source src="/audio/xmpp/beep.ogg"></audio>');
		}
		$('#audio')[0].play();
	}
}

window.getGookie = function( name ) {
	// не запрашивать есть ли новые письма слишком часто, если несколько табов
    var cookie = " " + document.cookie;
    var search = " " + name + "=";
    var setStr = null;
    var offset = 0;
    var end = 0;
    if ( cookie.length > 0 ) {
        offset = cookie.indexOf( search );
        if (offset != -1) {
            offset += search.length;
            end = cookie.indexOf( ";", offset )
            if ( end == -1 ) {
                end = cookie.length;
            }
            setStr = unescape( cookie.substring( offset, end ) );
        }
    }
    return(setStr);
}

window.text_limit = function(obj, count){
	//используется в initTextLimit
    var str = $(obj).val();
    if (str.length <= count){
        $('p.count span').html(count - str.length);
    } else {
        $('p.count span').html(0);
        $(obj).val(str.substr(0, count));
    }
}

window.initTextLimit = function(){
	//цветы, ограничение в открытке к цветам
    $('textarea.count').each(function(){text_limit($(this), 500);});
    $('textarea.count').keyup(function(){text_limit($(this), 500);});
}

window.addPhoto = function(obj, cur){
	// добавление еще одной формы для фотки в фидбэки и комментарии 8 мест
    var el = $(obj).find('p:last');
    var old = $(el).find('input').attr('name');
    var id = parseInt(old.replace('photo[photo', '').replace(']', '')) + 1;
    var next = 'photo[photo' + id + ']';
    $(obj).append('<p>' + $(el).html().replace(old, next) + '</p>');

    return false;
}

window.sendPost = function(url){
	//удаление альбомов, 1 место, можно заменить на форму
    $.post(url,{}, function(){},'script');
    return false;
}

window.letter_check_new = function(uid){
	//проверяем новое письмо когда в переписке
    var cook = getGookie( 'letter_check_' + uid);
    if ( cook == null) {
        newAjaxChange('', '/letter/check_insert_new/' + uid, '', 'script');
        var d = new Date();
        var time = d.getTime();
        setCookie( 'letter_check_' + uid, 1, time + 30 * 1000, '/');
    }
}

window.checkfunct = function(el, txt){
	if ($(el).parent().parent().find('.no_recurrent').size() > 0){
		if ($(el).parent().parent().find('.no_recurrent:checked').size() == 0){
			return false;
		}
	}

	return true;
}

window.showradio = function(el){
	//цели
    var sz = $(el).parent().parent().parent().find('.line input[type="checkbox"]:checked').length;
    if ($(el).is(':checked')){
        $(el).parent().parent().parent().children('label').find('input').prop('checked', true);
        $(el).parent().parent().parent().find('.radio2,.radio1').show();
    } else if (sz == 0) {
        $(el).parent().parent().parent().find('.radio2,.radio1').hide();
    }
}

window.setCookie = function( name, value, expires, path, domain, secure ) {
    var today = new Date();
    today.setTime( today.getTime() );

    if ( typeof expires == "number" && expires ) {
        var expires_date = new Date( expires );
    }

    document.cookie = name + '=' +escape( value ) +
        ( ( expires ) ? ';expires=' + expires_date.toUTCString() : '' ) +
        ( ( path ) ? ';path=' + path : '' ) +
        ( ( domain ) ? ';domain=' + domain : '' ) +
        ( ( secure ) ? ';secure' : '' );
}


var dateFormat = function () {
	var	token = /d{1,4}|m{1,4}|yy(?:yy)?|([HhMsTt])\1?|[LloSZ]|"[^"]*"|'[^']*'/g,
		timezone = /\b(?:[PMCEA][SDP]T|(?:Pacific|Mountain|Central|Eastern|Atlantic) (?:Standard|Daylight|Prevailing) Time|(?:GMT|UTC)(?:[-+]\d{4})?)\b/g,
		timezoneClip = /[^-+\dA-Z]/g,
		pad = function (val, len) {
			val = String(val);
			len = len || 2;
			while (val.length < len) val = "0" + val;
			return val;
		};

	// Regexes and supporting functions are cached through closure
	return function (date, mask, utc) {
		var dF = dateFormat;

		// You can't provide utc if you skip other args (use the "UTC:" mask prefix)
		if (arguments.length == 1 && Object.prototype.toString.call(date) == "[object String]" && !/\d/.test(date)) {
			mask = date;
			date = undefined;
		}

		// Passing date through Date applies Date.parse, if necessary
		date = date ? new Date(date) : new Date;
		if (isNaN(date)) throw SyntaxError("invalid date");

		mask = String(dF.masks[mask] || mask || dF.masks["default"]);

		// Allow setting the utc argument via the mask
		if (mask.slice(0, 4) == "UTC:") {
			mask = mask.slice(4);
			utc = true;
		}

		var	_ = utc ? "getUTC" : "get",
			d = date[_ + "Date"](),
			D = date[_ + "Day"](),
			m = date[_ + "Month"](),
			y = date[_ + "FullYear"](),
			H = date[_ + "Hours"](),
			M = date[_ + "Minutes"](),
			s = date[_ + "Seconds"](),
			L = date[_ + "Milliseconds"](),
			o = utc ? 0 : date.getTimezoneOffset(),
			flags = {
				d:    d,
				dd:   pad(d),
				ddd:  dF.i18n.dayNames[D],
				dddd: dF.i18n.dayNames[D + 7],
				m:    m + 1,
				mm:   pad(m + 1),
				mmm:  dF.i18n.monthNames[m],
				mmmm: dF.i18n.monthNames[m + 12],
				yy:   String(y).slice(2),
				yyyy: y,
				h:    H % 12 || 12,
				hh:   pad(H % 12 || 12),
				H:    H,
				HH:   pad(H),
				M:    M,
				MM:   pad(M),
				s:    s,
				ss:   pad(s),
				l:    pad(L, 3),
				L:    pad(L > 99 ? Math.round(L / 10) : L),
				t:    H < 12 ? "a"  : "p",
				tt:   H < 12 ? "am" : "pm",
				T:    H < 12 ? "A"  : "P",
				TT:   H < 12 ? "AM" : "PM",
				Z:    utc ? "UTC" : (String(date).match(timezone) || [""]).pop().replace(timezoneClip, ""),
				o:    (o > 0 ? "-" : "+") + pad(Math.floor(Math.abs(o) / 60) * 100 + Math.abs(o) % 60, 4),
				S:    ["th", "st", "nd", "rd"][d % 10 > 3 ? 0 : (d % 100 - d % 10 != 10) * d % 10]
			};

		return mask.replace(token, function ($0) {
			return $0 in flags ? flags[$0] : $0.slice(1, $0.length - 1);
		});
	};
}();

// Some common format strings
dateFormat.masks = {
	"default":      "ddd mmm dd yyyy HH:MM:ss",
	shortDate:      "m/d/yy",
	mediumDate:     "mmm d, yyyy",
	longDate:       "mmmm d, yyyy",
	fullDate:       "dddd, mmmm d, yyyy",
	shortTime:      "h:MM TT",
	mediumTime:     "h:MM:ss TT",
	longTime:       "h:MM:ss TT Z",
	isoDate:        "yyyy-mm-dd",
	isoTime:        "HH:MM:ss",
	isoDateTime:    "yyyy-mm-dd'T'HH:MM:ss",
	isoUtcDateTime: "UTC:yyyy-mm-dd'T'HH:MM:ss'Z'"
};

// Internationalization strings
dateFormat.i18n = {
	dayNames: [
		"Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat",
		"Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"
	],
	monthNames: [
		"Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec",
		"January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"
	]
};

// For convenience...
Date.prototype.format = function (mask, utc) {
	return dateFormat(this, mask, utc);
};
